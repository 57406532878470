import { IVendorUser } from "@smartrr/shared/entities/VendorUser";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { MigrationUploadResult, UploadedFileStatus } from "../../libs/types";
import { formatErrorMessage, getFileByName } from "../../libs/utils";
import { GroupedItemsStatus } from "../../libs/utils/constants";
import { z } from "zod";
import { useMemo } from "react";

const migrationStatusSchema = z.enum([
  "NO_FILE",
  "FILE_DROPPED",
  "FILE_UPLOADING",
  "FILE_ERROR",
  "FILE_UPLOADED",
  "FILE_COMMITTING",
  "FILE_COMMITTED",
]);

export type MigrationStatus = z.infer<typeof migrationStatusSchema>;

export const MigrationStatusEnum = migrationStatusSchema.Values;
export interface ManualMigrationStore {
  file: File | null;
  status: MigrationStatus;
  progress: number;
  errors: {
    critical: boolean;
    showError: boolean;
    errorMessage: string;
  };
  /* Used to update the progress bar */
  statuses: Array<UploadedFileStatus> | null;
  currentUploadedFileStatus: UploadedFileStatus | null;
  rows: {
    migratedRows: number;
    totalRows: number;
  };
  user: IVendorUser | null;

  actions: {
    fileUpload(file: File): Promise<void>;
    initializeMigration(file: File): Promise<void>;
    updateStatusList(list: UploadedFileStatus[]): void;
    removeFile(file: File): Promise<void>;
    setFile(file: File | null): void;
    setUser(user: IVendorUser | null): void;
    setDropzoneToInitial(): void;
  };

  internal: {
    setCurrentItemStatus(status: UploadedFileStatus | null): void;
    checkCurrentUploadStatus(file: File): Promise<void>;
  };
}

const manualMigrationSlice: StateCreator<ManualMigrationStore, [["zustand/immer", never]]> = (set, get) => ({
  errors: {
    critical: false,
    errorMessage: "",
    showError: false,
  },
  file: null,
  status: MigrationStatusEnum.NO_FILE,
  isUploading: false,
  progress: 0,
  statuses: null,
  currentUploadedFileStatus: null,
  rows: {
    totalRows: 0,
    migratedRows: 0,
  },
  uploadComplete: false,
  user: null,

  actions: {
    setFile(file): void {
      set(draft => {
        draft.file = file;
        draft.status = MigrationStatusEnum.FILE_DROPPED;
      });
    },

    async fileUpload(file) {
      set(draft => {
        draft.progress = 0;
        draft.currentUploadedFileStatus = null;
      });
      const formData = new FormData();
      formData.append("csvFile", file);

      const user = get().user;

      if (!user) {
        return;
      }

      set({
        status: MigrationStatusEnum.FILE_UPLOADING,
      });

      const response = await typedFrontendVendorApi.postReq("/migration/upload-file", {
        headers: {
          "Content-Disposition": `attachment: uploadBy=${user.originalFirstName} ${user.originalLastName}`,
        },
        body: formData as any,
      });

      if (response.type === "error" || response.type !== "success") {
        const parsedErrorMessage = formatErrorMessage(response.message);
        set(draft => {
          draft.errors.critical = true;
          draft.errors.showError = true;
          draft.errors.errorMessage = parsedErrorMessage;
          draft.status = MigrationStatusEnum.FILE_ERROR;
        });
        return;
      }

      const resultRows: MigrationUploadResult[] = response.body;

      const invalidRows = resultRows.filter(row => row.status === GroupedItemsStatus.INVALID);
      if (invalidRows.length) {
        const errors =
          `In ${file.name}:\n` +
          invalidRows.map(row => {
            return `id ${row.externalSubscriptionId ?? ""}: ${row.errorMessage ?? ""}\n`;
          });

        set(draft => {
          draft.errors.critical = false;
          draft.errors.showError = true;
          draft.errors.errorMessage = errors;
          draft.status = MigrationStatusEnum.FILE_ERROR;
        });
        return;
      }

      set(draft => {
        draft.file = file;
        draft.status = MigrationStatusEnum.FILE_UPLOADED;
      });
    },

    async removeFile(file) {
      set(draft => {
        draft.status = MigrationStatusEnum.FILE_UPLOADING;
      });

      const response = await typedFrontendVendorApi.deleteReq("/migration/upload-file/:fileName", {
        params: { fileName: file.name },
      });

      if (response.type === "error") {
        const parsedErrorMessage = formatErrorMessage(response.message);
        set(draft => {
          draft.errors.critical = true;
          draft.errors.showError = true;
          draft.errors.errorMessage = parsedErrorMessage;
          draft.status = MigrationStatusEnum.FILE_ERROR;
        });
        return;
      }

      get().actions.setDropzoneToInitial();
    },

    setUser(user) {
      if (user) {
        set(draft => {
          draft.user = user;
        });
      }
    },

    updateStatusList(list: UploadedFileStatus[]): void {
      set(draft => {
        draft.statuses = list;
      });
      const file = get().file;
      file && get().internal.checkCurrentUploadStatus(file);
    },

    async initializeMigration() {
      set(draft => {
        draft.status = MigrationStatusEnum.FILE_COMMITTING;
      });
      await typedFrontendVendorApi.postReq("/migration/process");
    },
    setDropzoneToInitial() {
      set(draft => {
        draft.progress = 0;
        draft.file = null;
        draft.errors.errorMessage = "";
        draft.errors.showError = false;
        draft.status = MigrationStatusEnum.NO_FILE;
        draft.currentUploadedFileStatus = null;
      });
    },
  },

  internal: {
    setCurrentItemStatus(status) {
      if (!status) {
        return;
      }

      const anyPending = status.groupedItems.some(item => item.status === GroupedItemsStatus.PENDING);

      const anyProcessing = status.groupedItems.some(item => item.status === GroupedItemsStatus.PROCESSING);

      if (!anyPending && !anyProcessing) {
        setTimeout(get().actions.setDropzoneToInitial, 5000);
        set(draft => {
          draft.status = MigrationStatusEnum.FILE_COMMITTED;
        });
      }
    },

    async checkCurrentUploadStatus(file) {
      const statuses = get().statuses;
      const status = get().status;

      if (!statuses) {
        return;
      }

      const foundFile = getFileByName(statuses, file);

      if (!foundFile) {
        if (status !== MigrationStatusEnum.FILE_ERROR) {
          set(draft => {
            draft.file = null;
          });
          throw new Error("File cannot be found");
        }
        return;
      }

      const totalRows = foundFile.groupedItems.reduce((acc, currentItem) => {
        return acc + currentItem.count;
      }, 0);
      const migratedRows = foundFile.groupedItems.reduce((acc, currentItem) => {
        if (
          currentItem.status !== GroupedItemsStatus.PENDING &&
          currentItem.status !== GroupedItemsStatus.PROCESSING
        ) {
          return acc + currentItem.count;
        }
        return acc;
      }, 0);

      set(draft => {
        draft.currentUploadedFileStatus = foundFile;
        draft.rows.totalRows = totalRows;
        draft.rows.migratedRows = migratedRows;
        draft.progress = Math.trunc((migratedRows / totalRows) * 100);
      });
      const progress = get().progress;
      if (progress === 100) {
        set(draft => {
          draft.status = MigrationStatusEnum.FILE_COMMITTED;
        });
      }
      this.setCurrentItemStatus(foundFile);
    },
  },
});

const useManualMigrationStore = create<ManualMigrationStore>()(immer(manualMigrationSlice));

const initialStoreState = useManualMigrationStore.getState();

export const ManualMigrationStoreAccess = {
  useActions() {
    return useManualMigrationStore(state => state.actions);
  },

  useError() {
    return useManualMigrationStore(state => state.errors);
  },

  useFile() {
    return useManualMigrationStore(state => state.file);
  },

  useProgress() {
    return useManualMigrationStore(state => state.progress);
  },

  useRows() {
    return useManualMigrationStore(state => state.rows);
  },

  useStatuses() {
    return useManualMigrationStore(state => state.statuses);
  },

  useCurrentUploadedFileStatus() {
    return useManualMigrationStore(state => state.currentUploadedFileStatus);
  },

  useMigrationStatus() {
    return useManualMigrationStore(state => state.status);
  },

  useIsUploadingOrCommitting() {
    const status = ManualMigrationStoreAccess.useMigrationStatus();

    return useMemo(() => {
      return status === MigrationStatusEnum.FILE_COMMITTING || status === MigrationStatusEnum.FILE_UPLOADING;
    }, [status]);
  },

  useIsUploadedOrCommitted() {
    const status = ManualMigrationStoreAccess.useMigrationStatus();

    return useMemo(() => {
      return status === MigrationStatusEnum.FILE_COMMITTED || status === MigrationStatusEnum.FILE_UPLOADED;
    }, [status]);
  },

  testing: {
    store: useManualMigrationStore,

    actions() {
      return {
        actions: useManualMigrationStore.getState().actions,
        internal: useManualMigrationStore.getState().internal,
      };
    },

    reset() {
      return useManualMigrationStore.setState(initialStoreState);
    },
  },
};
